<template>
  <div
    class="flex content-center items-center top-0 absolute overflow-hidden -z-20  justify-end -right-16"
    style="max-height: 200px;">

    <svg
      id="svgBg"
      width="576"
      height="516"
      viewBox="0 0 576 516"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <line
        x1="85.1768"
        y1="26.8232"
        x2="492.177"
        y2="433.823"
        stroke="#CBCBCB"
        stroke-width="0.5"
      />
      <line
        x1="84.8232"
        y1="433.823"
        x2="491.823"
        y2="26.8232"
        stroke="#CBCBCB"
        stroke-width="0.5"
      />
      <circle
        cx="288"
        cy="236"
        r="71.5"
				class="circle"
        stroke="#B9B9B9"
        stroke-dasharray="2 2"
      />
      <circle
        cx="288"
        cy="236"
        r="143.5"
				class="circle"
        stroke="#B9B9B9"
        stroke-dasharray="2 2"
      />
      <circle
        cx="288"
        cy="228"
        r="287.5"
				class="circle"	
        stroke="#B9B9B9"
        stroke-dasharray="2 2"
      />
      <!-- <circle cx="288" cy="235" r="16"  fill="url(#pattern0)" /> -->
			<g class="rotate-inner">
				<circle cx="288" cy="165" r="16" class="thumb-inner" fill="url(#pattern1)" />
				<circle cx="288" cy="307" r="16" class="thumb-inner" fill="url(#pattern10)" />
				<circle cx="360" cy="235" r="16" class="thumb-inner" fill="url(#pattern11)" />
				<circle cx="216" cy="235" r="16" class="thumb-inner" fill="url(#pattern12)" />
			</g>

			<g class="rotate-outer">
      <circle cx="288" cy="92" r="16" class="thumb-outer" fill="url(#pattern2)" />
      <circle cx="389" cy="132" r="16" class="thumb-outer" fill="url(#pattern3)" />
      <circle cx="393" cy="338" r="16" class="thumb-outer" fill="url(#pattern5)" />
      <circle cx="288" cy="379" r="16" class="thumb-outer" fill="url(#pattern6)" />
      <circle cx="187" cy="338" r="16" class="thumb-outer" fill="url(#pattern7)" />
      <circle cx="187" cy="132" r="16" class="thumb-outer" fill="url(#pattern8)" />
			<circle cx="432" cy="235" r="16" class="thumb-outer" fill="url(#pattern4)" />
				<circle cx="144" cy="235" r="16" class="thumb-outer" fill="url(#pattern9)" />
			</g>
			


      <defs>
        <pattern
          :id="`pattern${index}`"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
					class="pattern"
					v-for="(chan, index) in channels"
					:key="chan.id"
        >
          <use :xlink:href="`#thumb${index}`" transform="scale(0.00568182)" />
        </pattern>

        <image
          :id="`thumb${index}`"
					v-for="(chan, index) in channels"
					:key="`${chan.id}_${index}thumbimg`"
          width="176"
          height="176"
					class="image"
          :href="chan.thumbnail"
        />
      </defs>
    </svg>
  </div>
</template>

<script>
import anime from 'animejs';


export default {
  name: "Header",
  props: ["channels"],
  mounted() {
		console.log('channel')
		console.log(this.channels);

		anime({
			targets: '.circle',
			rotate: {
				value: -360,
				duration: 240000,
				easing: 'linear'
			},
			loop: true
		});

		anime({
			targets: '.rotate-inner',
			rotate: {
				value: 360,
				duration: 120000,
				easing: 'linear'
			},
			loop: true
		});

		anime({
			targets: '.rotate-outer',
			rotate: {
				value: 360,
				duration: 240000,
				easing: 'linear'
			},
			loop: true
		});

		anime({
			targets: '.thumb-outer',
			rotate: {
				value: -360,
				duration: 240000,
				easing: 'linear'
			},
			loop: true
		});

		anime({
			targets: '.thumb-inner',
			rotate: {
				value: -360,
				duration: 120000,
				easing: 'linear'
			},
			loop: true
		});


	},
};
</script>

<style>
svg .rotate-inner, .rotate-outer, svg .thumb-outer, .thumb-inner, .circle {
  transform-box: fill-box;
  transform-origin: center;
}

#svgBg {
  transform: perspective(621px) rotateY(350deg);
}
</style>